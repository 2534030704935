const getFilteredDrawerData = (
    drawerSelectedRow: { 
        sourceData: { label: string; value: string; }[]; 
        targetData: { label: string; value: string; }[]; 
    },
    filterValue: string) => {
    const { sourceData, targetData } = drawerSelectedRow;

    if (filterValue && filterValue.length > 0) {
        const filteredSource = sourceData.filter((item) => item.label.toLowerCase().includes(filterValue.toLowerCase()));
        const filteredTarget = targetData.filter((item) => item.label.toLowerCase().includes(filterValue.toLowerCase()));

        return {
            sourceData: filteredSource,
            targetData: filteredTarget
        };
    }

    return drawerSelectedRow;
}

export default getFilteredDrawerData;