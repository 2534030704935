import { AccessorKeyColumnDef } from "@tanstack/react-table";
import { IFieldValueGroupMappingRow, MappingGroup, MappingGroupValues, MappingGroupValuesDefault, System } from "../../../models/models";

const createDrawerModel = (data: any,
    columnDef: { sourceColDef: AccessorKeyColumnDef<any>[]; targetColDef: AccessorKeyColumnDef<any>[]; },
    selectedMappingGroupData: IFieldValueGroupMappingRow[]) => {

        debugger;
    const { sourceColDef, targetColDef } = columnDef;
    let model: MappingGroupValues = MappingGroupValuesDefault();

    sourceColDef.forEach((col) => {
        if (col.header) {
            model.sourceData.push({ label: col.header.toString(), value: data[col.accessorKey].toString() });
        }
    });

    targetColDef.forEach((col) => {
        if (col.header) {
            const label = col.header.toString();
            const value = data[col.accessorKey] ? data[col.accessorKey].toString() : '';
            model.targetData.push({ label: label, value: value });
        }
    });

    const keyValues = model.sourceData.map((row) => row.value);

    const mappingGroups = selectedMappingGroupData
        .filter((row) => row.fromGroupMappings.map(m => m.fieldValue).every(f => keyValues.includes(f)))
        .map(row => ({
            fromGroupId: row.fromGroupMappings[0].groupId,
            fromSystem: {
                id: row.fromGroupMappings[0].systemId,
                name: row.fromGroupMappings[0].systemName
            } as System,
            toGroupId: row.toGroupMappings[0].groupId,
            toSystem: {
                id: row.toGroupMappings[0].systemId,
                name: row.toGroupMappings[0].systemName
            } as System
        } as MappingGroup)).pop();

    if (mappingGroups) {
        model.mappingGroup = mappingGroups;
    }
    return model;
};

export default createDrawerModel;