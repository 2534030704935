import MappingActions from "./MappingActions";
import DrawerForm from "./DrawerForm";
import createDrawerModel from "../utils/createDrawerModel";
import getFilteredDrawerData from "../utils/getFilteredDrawerData";
import deleteFromMappingDataSet from "../utils/deleteFromMappingDataSet";
import updateMappingDataSet from "../utils/updateMappingDataSet";
import getColumnDuplicates from "../utils/getColumnDuplicates";
import generateTableData from "../utils/generateTableData";
import ConfirmModalHeader from "./ConfirmModalHeader";
import { IFieldValueGroupMappingRow, MappingGroupValues, MappingGroupValuesDefault } from "../../../models/models";
import { Drawer, Variants, Sentiments, BaseTable, Modal, Sizes, Spacings, DrawerAction, Tabs } from "@sede-x/shell-ds-react-framework";
import { useDeleteMapping } from "../../../api/deleteMapping";
import { useUpdateMapping } from "../../../api/updateMapping";
import { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { getColumnDefinition } from "../../../utils/getColumnDefinition";
import HistoryChanges from "./HistoryChanges";


const MappingGroupDetailsView = ({
  selectedMappingGroup,
  mappingGroupInfo,
  setAlertHidden,
  setInfo,
  isAdmin
}: {
  selectedMappingGroup: string;
  mappingGroupInfo: Map<string, Map<number, IFieldValueGroupMappingRow>>;
  setAlertHidden: (value: { hidden: boolean, message: string, alertType: string }) => void;
  setInfo: (value: Map<string, Map<number, IFieldValueGroupMappingRow>>) => void;
  isAdmin: boolean;
}) => {

  const [editMode, setEditMode] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerSelectedRow, setDrawerSelectedRow] = useState<MappingGroupValues>(MappingGroupValuesDefault());
  const [filterValue, setFilterValue] = useState<string>('');
  const [modalOpen, setModalOpen] = useState(false);

  const [activeKey, setActiveKey] = useState("M");
  const [showChangeHistory, setShowChangeHistory] = useState(false);

  const handleClose = (resetSelectedRow: boolean) => {
    setOpenDrawer(false);
    setEditMode(false);
    setFilterValue('');

    if (resetSelectedRow) {
      setDrawerSelectedRow(MappingGroupValuesDefault());
      setFormItems([]);
      setTrackerItems([]);
    }
  };
  const selectedMappingGroupData = Array.from(mappingGroupInfo?.get(selectedMappingGroup)?.entries() ?? []).map((row) => row[1]);
  const columnDef = getColumnDefinition(mappingGroupInfo, selectedMappingGroup);

  const deleteMutation = useDeleteMapping();
  const updateMutation = useUpdateMapping();

  const staticColumns: Array<ColumnDef<any>> = [
    {
      header: "Actions",
      accessorKey: "actions",
      cell: ({ row }) => (
        <div className="px-1">
          <MappingActions
            handleViewClick={() => {
              setEditMode(false);
              setDrawerSelectedRow(createDrawerModel(row.original, columnDef, selectedMappingGroupData));
              setOpenDrawer(true)
            }}
            handleEditClick={() => {
              setEditMode(true);
              setDrawerSelectedRow(createDrawerModel(row.original, columnDef, selectedMappingGroupData));
              setOpenDrawer(true);
            }}

            handleDeleteClick={() => {
              setEditMode(false);
              var model = createDrawerModel(row.original, columnDef, selectedMappingGroupData)

              const mappingGroupIds = { fromGroupId: model.mappingGroup.fromGroupId.toString(), toGroupId: model.mappingGroup.toGroupId.toString() };
              const mappingGroupKey = model.sourceData.map((row) => row.label).join(" - ");
              const mappingGroupValue = model.sourceData.map((row) => row.value).join(" - ");
              const messageLabel = mappingGroupValue + " mapping group is deleted";

              return ({
                successCallbackFn: () => {
                  setDrawerSelectedRow(model);
                  setOpenDrawer(false);

                  deleteMutation.mutate(mappingGroupIds);
                  const mappingDataSet = deleteFromMappingDataSet(mappingGroupInfo, mappingGroupIds, mappingGroupKey);
                  setInfo(mappingDataSet);
                  setAlertHidden({ hidden: false, message: messageLabel, alertType: "information" });
                },
                cancelCallbackFn: () => setAlertHidden({ hidden: true, message: "", alertType: "information" }),
                errorCallbackFn: () => setAlertHidden({ hidden: false, message: "Error deleting mapping group: " + messageLabel, alertType: "error" })
              });
            }}
          />
        </div>
      ),
    },
  ];

  const drawerData = getFilteredDrawerData(drawerSelectedRow, filterValue);

  const [formItemsList, setFormItems] = useState(drawerData.targetData);
  const [trackerItems, setTrackerItems] = useState<{ label: string, value: string, index: number }[]>([]);

  if (selectedMappingGroupData) {
    const { sourceColDef, targetColDef } = columnDef;

    const duplicatesMap = getColumnDuplicates(selectedMappingGroupData);

    const tableData = generateTableData(selectedMappingGroupData, duplicatesMap, columnDef);

    const stickyColumns = sourceColDef.map(col => col.accessorKey as string);

    const drawerActions = !isAdmin || showChangeHistory ? [] : [
      {
        label: "Delete Mapping",
        action: () => {
          setModalOpen(true);
          handleClose(false);
        },
        props: {
          variant: Variants.Outlined,
          sentiment: Sentiments.Negative,
        },
      } as DrawerAction,
      {
        label: editMode === true ? "Update Mapping" : "Edit Mapping",
        action: () => {
          if (editMode === true) {
            var model = drawerSelectedRow;
            const mappingGroupIds = { fromGroupId: model.mappingGroup.fromGroupId, toGroupId: model.mappingGroup.toGroupId };
            const mappingGroupKey = model.sourceData.map((row) => row.label).join(" - ");
            const mappingGroupValue = model.sourceData.map((row) => row.value).join(" - ");
            const messageLabel = mappingGroupValue + " mapping group is updated";
            const updates = updateMappingDataSet(mappingGroupInfo, mappingGroupIds, mappingGroupKey, trackerItems);
            updateMutation.mutate({ updates });
            setAlertHidden({ hidden: false, message: messageLabel, alertType: "success" });
            handleClose(true);

          } else {
            setEditMode(!editMode);
          }
        },
      } as DrawerAction
    ];


    const tabsItems = [{
      label: 'Selected Mappings',
      key: 'M',
      children: <DrawerForm drawerData={drawerData}
        toSystem={{ id: drawerSelectedRow.mappingGroup.toSystem.id, name: drawerSelectedRow.mappingGroup.toSystem.name, urlFriendlyName: '', totalMappingGroups: 0, modifiedBy: 'system' }}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        setFormItems={setFormItems}
        trackerItems={trackerItems}
        setTrackerItems={setTrackerItems}
        editMode={editMode} />
    }, {
      label: 'History of Changes',
      key: 'H',
      children: <HistoryChanges toGroupId={drawerSelectedRow.mappingGroup?.toGroupId ?? -1} />
    }];

    const onTabChange = (key: string) => {
      setActiveKey(key);
      if (key === 'H') {
        setShowChangeHistory(true);
      } else {
        setShowChangeHistory(false);
      }
    };



    return (
      <>
        <div
          style={{
            width: "100vw",
            backgroundColor: "snow",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginTop: "2rem",
              marginLeft: "1.5rem",
              marginRight: "1.5rem",
              position: "relative",
              maxWidth: "100%",
              overflowX: "auto"
            }}
          >
            <Drawer
              open={openDrawer}
              header="Mapping"
              width="25%"
              sticky
              onClose={() => handleClose(true)}
              actions={drawerActions}>
                <Tabs items={tabsItems} onChange={onTabChange} tabPosition="top" activeKey={activeKey} size="large" emphasis={true} />
            </Drawer>

            <Modal title={<ConfirmModalHeader headerText="Are you sure you want to delete this mapping?" fontColor="rgb(221, 29, 33)" />}
              open={modalOpen} padding={Spacings.Tight}
              size={Sizes.Small} centered closable={false}
              actions={[{
                props: { variant: Variants.Outlined },
                label: "Cancel",
                action: () => { setModalOpen(false) }
              },
              {
                props: { variant: Variants.Filled, sentiment: Sentiments.Negative },
                label: "Confirm",
                action: () => {

                  setEditMode(false);
                  var model = drawerSelectedRow;

                  const mappingGroupIds = { fromGroupId: model.mappingGroup.fromGroupId.toString(), toGroupId: model.mappingGroup.toGroupId.toString() };
                  const mappingGroupKey = model.sourceData.map((row) => row.label).join(" - ");
                  const mappingGroupValue = model.sourceData.map((row) => row.value).join(" - ");
                  const messageLabel = mappingGroupValue + " mapping group is deleted";
                  deleteMutation.mutate(mappingGroupIds);
                  const mappingDataSet = deleteFromMappingDataSet(mappingGroupInfo, mappingGroupIds, mappingGroupKey);

                  setInfo(mappingDataSet);
                  setAlertHidden({ hidden: false, message: messageLabel, alertType: "information" });
                  setModalOpen(false);
                }
              }]} onClose={() => { }} >

              <p style={{
                paddingTop: "1.5rem",
                textAlign: 'center',
                fontFamily: "Shell Font",
                fontWeight: "600",
                fontSize: "20px",
                lineHeight: "24px",
                letterSpacing: "0em",
                fontStyle: "normal",
                margin: "unset",
                color: "rgb(52, 52, 52)"
              }}>You cannot undo this action!</p>
            </Modal>

            <BaseTable
              columns={[...sourceColDef, ...targetColDef, ...staticColumns]}
              data={tableData}
              maxHeight={550}
              maxWidth={window.innerWidth - 20}
              stickyColumns={stickyColumns}
              width="100%"
              stickyFooter
              stickyHeader
            />
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default MappingGroupDetailsView;
