import { Dictionary } from "lodash";
import { IFieldValue, IFieldValueGroupMapping, IFieldValueGroupMappingRow, MappingGroupValues } from "../../../models/models";

const saveMappingDataSet = (
    data: Map<string, Map<number, IFieldValueGroupMappingRow>>,
    allFields: Dictionary<IFieldValue[]> | undefined,
    mappingGroupKey: string,
    newItems: MappingGroupValues) => {

    const inserts = new Array<IFieldValueGroupMapping>();
    const mappingDataSet = data.get(mappingGroupKey);

    const fromSystemId = newItems.mappingGroup.fromSystem.id;
    const fromGroupName = newItems.mappingGroup.fromGroupName;

    const toSystemId = newItems.mappingGroup.toSystem.id;
    const toGroupName = newItems.mappingGroup.toGroupName; 

    if (mappingDataSet && allFields) {

        //create 2 mapping groups for this one

        for (const sourceDataItem of newItems.sourceData) {
            const sourceField = allFields[sourceDataItem.label].find(x => x.value === sourceDataItem.value && x.systemId === fromSystemId);
            if (sourceField) {
                const newMapping = {
                    id: 0,
                    fieldValueId: sourceField.id,
                    fieldValue: sourceField.value,
                    fieldName: sourceField.fieldName,
                    fieldId: sourceField.fieldId,
                    systemName: sourceField.systemName,
                    systemId: sourceField.systemId,
                    groupId: 0,
                    groupName: fromGroupName,
                    modifiedBy: "system"
                };
                inserts.push(newMapping);
            }
        }

        for (const targetDataItem of newItems.targetData) {
            const targetField = allFields[targetDataItem.label].find(x => x.value === targetDataItem.value && x.systemId === toSystemId);
            if (targetField) {
                const newMapping = {
                    id: 0,
                    fieldValueId: targetField.id,
                    fieldValue: targetField.value,
                    fieldName: targetField.fieldName,
                    fieldId: targetField.fieldId,
                    systemName: targetField.systemName,
                    systemId: targetField.systemId,
                    groupId: 0,
                    groupName: toGroupName,
                    modifiedBy: "system"
                };
                inserts.push(newMapping);
            }
        }
    }

    return inserts;
};

export default saveMappingDataSet;